import Image, { StaticImageData } from 'next/image'

type BackgroundProps = {
  imgSrc?: StaticImageData
  imgAlt?: string
  bgColor?: string
  children?: React.ReactNode
}

export default function Background({
  imgSrc,
  imgAlt,
  bgColor,
  children,
}: BackgroundProps) {
  return (
    <div className="relative min-h-screen">
      <div className={`absolute inset-0 -z-10 ${bgColor}`}>
        {imgSrc && imgAlt ? (
          <Image src={imgSrc} alt={imgAlt} fill className="hidden xl:block" />
        ) : null}
      </div>
      {children}
    </div>
  )
}
