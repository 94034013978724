import { SvgIconProps } from '@/types/helpers'

export default function Warning(props: SvgIconProps) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2624 1.04914C6.02965 -0.297183 7.97042 -0.297184 8.73768 1.04914L13.2743 9.00978C14.0342 10.3431 13.0713 12 11.5367 12H2.4634C0.928786 12 -0.0340715 10.3431 0.725756 9.00978L5.2624 1.04914ZM7.00001 7.33337C7.3682 7.33337 7.66668 7.03489 7.66668 6.6667V3.33337C7.66668 2.96518 7.3682 2.66671 7.00001 2.66671C6.63182 2.66671 6.33334 2.96518 6.33334 3.33337V6.6667C6.33334 7.03489 6.63182 7.33337 7.00001 7.33337ZM7.00001 10C7.3682 10 7.66668 9.70156 7.66668 9.33337C7.66668 8.96518 7.3682 8.66671 7.00001 8.66671C6.63182 8.66671 6.33334 8.96518 6.33334 9.33337C6.33334 9.70156 6.63182 10 7.00001 10Z"
        fill="currentFill"
      />
    </svg>
  )
}
