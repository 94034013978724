import { signInEmail } from '@/auth/actions/signIn'
import { validateCallbackURL } from '@/auth/actions/validateCallbackURL'

type CredentialsOptions = {
  provider: 'credentials'
  email: string
  password: string
  callbackUrl: string
}

type SocialOptions = {
  provider: 'google' | 'naver' | 'kakao'
  callbackUrl: string
}

type Options = CredentialsOptions | SocialOptions

async function signInCredentials(opts: CredentialsOptions) {
  const { email, password, callbackUrl } = opts

  const signInResult = await signInEmail(email, password)
  const validatedCallbackUrl = await validateCallbackURL(callbackUrl)

  switch (signInResult) {
    case 'USER_NOT_FOUND':
    case 'INCORRECT_PASSWORD':
      const loginErrURL = new URL(window.location.href)
      loginErrURL.searchParams.set('error', signInResult)

      window.location.href = loginErrURL.toString()
      return
    default:
      break
  }

  window.location.href = validatedCallbackUrl
}

function signInSocial(opts: SocialOptions) {
  const { provider, callbackUrl } = opts
  debugger

  const url = `/api/auth/${provider}/login?callbackUrl=${encodeURIComponent(callbackUrl)}`

  window.location.href = url
}

export function signIn(opts: Options) {
  switch (opts.provider) {
    case 'credentials':
      return signInCredentials(opts)
    case 'google':
    case 'naver':
    case 'kakao':
      return signInSocial(opts)
    default:
      break
  }
}
